<template>
  <div v-if="true">
    <page-heading
      heading="Управление процессами"
    >
      <span slot="help"></span>
      <div slot="right">

        <CompensationProcedureCreateModal
          @procedure-created="loadProcedures"
        >
          <el-button
            slot="reference"
            size="small"
            type="primary"
            icon="fas fa-plus"
          >
            Создать процедуру
          </el-button>
        </CompensationProcedureCreateModal>

      </div>
    </page-heading>
    <el-card>

      <el-table
        ref="proceduresTable"
        style="width: 100%"
        :data="procedures"
        empty-text="Нет созданных процедур"
        v-loading="loading"
        :row-key="(row) => row.id"
        @sort-change="sortChanged($event, loadProcedures)"
      >
        <el-table-column label="Название процедуры" prop="name" sortable>
        </el-table-column>
        <el-table-column label="Тип процедуры" width="220" v-slot="scope" prop="type" sortable>
          {{ procedureTypes[scope.row.type] }}
        </el-table-column>
        <el-table-column label="Год" width="80" prop="year" sortable>
        </el-table-column>
        <el-table-column label="Период" v-slot="scope" width="200">
          {{ new Date(scope.row.date_start).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) }}
          -
          {{ new Date(scope.row.date_end).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) }}
        </el-table-column>
        <el-table-column label="Участники" width="120" prop="users_count">
        </el-table-column>
        <el-table-column label="Статус" v-slot="scope" width="130" prop="status">
          {{ procedureStatuses[scope.row.status] }}
        </el-table-column>

        <el-table-column v-slot="scope" width="150">
          <div style="display: flex; justify-content: space-between">

            <el-tooltip
              content="Иерархия"
              placement="top"
              :open-delay="500"
            >
              <router-link
                :to="{ name: 'CompensationProcedureTree', params: { id: scope.row.id } }"
                target="_blank"
              >
                <el-button
                  class="table-action-button"
                  round
                  size="mini"
                  icon="fas fa-users"
                >
                </el-button>
              </router-link>
            </el-tooltip>

            <el-popconfirm
              v-if="true"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              :title="scope.row.published ? 'Вы уверены, что хотите снять процедуру с публикации?' : 'Вы уверены, что хотите опубликовать процедуру?'"
              @confirm="setPublished(scope.row, !scope.row.published)"
            >
              <el-button
                slot="reference"
                class="table-action-button"
                size="mini"
                round
                :icon="scope.row.published ? 'fas fa-eye' : 'fas fa-eye-slash'"
              ></el-button>
            </el-popconfirm>

            <el-tooltip content="Редактировать" placement="top" :open-delay="500">
              <router-link
                :to="{ name: 'CompensationProcedureEdit', params: { id: scope.row.id } }"
                target="_blank"
              >
                <el-button
                  class="table-action-button"
                  round
                  size="mini"
                  icon="fas fa-pen"
                >
                </el-button>
              </router-link>
            </el-tooltip>

            <el-popconfirm
              v-if="true"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить процедуру?"
              @confirm="deleteProcedure(scope.row)"
            >
              <el-button
                slot="reference"
                class="table-action-button"
                size="mini"
                round
                icon="far fa-trash-alt"
              ></el-button>
            </el-popconfirm>
          </div>
        </el-table-column>
      </el-table>

      <paginator
        :paginator.sync="paginator"
        use-router-query-param
        @page-changed="loadProcedures"
      ></paginator>

    </el-card>
  </div>
</template>
<script>
import requestSender from "@/api/base/requestSender";
import PageHeading from "@/components/PageHeading";
import Paginator from "@/components/Paginator";
import {paginator} from "@/mixins/paginator";
import {mapGetters} from "vuex";
import {sorter} from "@/mixins/sorter";
import DeletedCardsModal from "@/components/cards/DeletedCardsModal.vue";
import CompensationProcedureCreateModal
  from "@/componentsCompany/smz/compensation/procedures/CompensationProcedureCreateModal.vue";

export default {
  name: 'CompensationProcedures',
  components: {
    CompensationProcedureCreateModal,
    DeletedCardsModal,
    Paginator,
    PageHeading,
  },
  mixins: [paginator, sorter],
  computed: {
    ...mapGetters(['user']),
  },
  watch: {},
  data() {
    return {
      loading: false,
      procedures: [],
      procedureTypes: this.$companyConfiguration.settings.getEnum('compensation_procedure_types'),
      procedureStatuses: this.$companyConfiguration.settings.getEnum('compensation_procedure_statuses'),

    }
  },
  mounted() {
    this.loadProcedures();
  },
  methods: {
    loadProcedures() {
      this.loading = true;

      requestSender('get', 'compensation-procedure/search', {

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        expand: ['users_count'].join(),
      })
        .then(({data, paginator, sorter}) => {
          this.procedures = data.procedures;
          this.paginatorFill(paginator);
          this.sorterFill(sorter);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteProcedure(procedure) {
      this.loading = true;

      requestSender('post', 'compensation-procedure/delete', {
        id: procedure.id
      })
        .then(() => {
          this.loadProcedures();
        })
    },

    setPublished(procedure, value) {
      this.loading = true;

      requestSender('post', 'compensation-procedure/set-published', {
        procedure_id: procedure.id,
        published: value,
      })
        .then(() => {
          this.loadProcedures();
        })
    },


  }
}
</script>

<style scoped lang="scss"></style>